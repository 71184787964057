<template>
  <view-wrapper title="新建询价">
    <div class="bg-white">
      <div class="flex justify-end pr-[24px] pt-[36px] pb-[24px]">
        <a-button v-if="id === 'add'" @click="serverDrawer.open" class="mr-[12px]">从估价单添加</a-button>
        <a-button v-if="id === 'add'" @click="excelDrawer.visible = true">从EXCEL导入</a-button>
      </div>
      <div class="ml-[118px] pr-[24px]">
        <div class="relative border-l-[2px] border-l-primary pl-[12px]">
          <div class="absolute left-[-115px] flex items-center">
            <div class="w-[22px] h-[70px] text-[50px] italic mr-[26px] leading-[70px]">1</div>
            <div class="w-[28px] h-[36px]">添加配件</div>
          </div>
          <div class="h-[96px] bg-[#d0f3f3] flex justify-center items-center">
            <div class="w-3/10">
              <a-input-search
                placeholder="请输入VIN码"
                size="large"
                :disabled="isReadonly"
                v-model:value.trim="inquiryData.vin"
                @search="vinSearchClick"
                @change="vinSearchChange"
                @blur="vinSearchClick"
              />
              <div class="text-[#ff4d00] mt-[8px]" v-if="flag.ishavedVin">
                <span>{{ inquiryData.vehicleBrand }}</span>
                <span class="ml-4px">{{ inquiryData.vehicleSubBrand }}</span>
                <span class="ml-4px">
                  {{ `${inquiryData.vehicleSystem} ${inquiryData.vehicleYear} ${inquiryData.vehicleDisplacement}` }}
                </span>
                <span class="ml-8px">{{ inquiryData.vehiclePlateNo }}</span>
              </div>
            </div>
          </div>
          <div class="bg-[#f5f5f5]">
            <div class="h-[63px] flex items-center ml-[24px]">
              <a-checkbox-group v-model:value="inquiryData.qualityTypeCodes">
                <a-checkbox
                  name="type"
                  v-for="item of qualityData"
                  :key="item.code"
                  :value="item.code"
                  class="mr-[12px] ml-[24px]"
                  :disabled="isReadonly"
                  >{{ item.message }}</a-checkbox
                >
              </a-checkbox-group>
            </div>
            <div class="ml-[24px]">
              <a-table
                :columns="columns"
                row-key="id"
                :data-source="inquiryData.inquiryGoods"
                :pagination="false"
                class="mb-[16px]"
              >
                <template #index="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template #customName>
                  <span>
                    <span class="mr-[2px] text-[#ff4d00]">*</span>
                    <span>配件名称</span>
                  </span>
                </template>
                <template #name="{ record }">
                  <span class="relative">
                    <a-select-goods
                      :disabled="isReadonly"
                      placeholder="请输入或选择配件名称"
                      v-model="record.enocloudGoods"
                      @change="onTableEnocloudGoodsChange(record)"
                    ></a-select-goods>
                  </span>
                </template>
                <template #customCount>
                  <span>
                    <span class="text-[#ff4d00] mr-[2px]">*</span>
                    <span>数量</span>
                  </span>
                </template>
                <template #count="{ record }">
                  <div class="w-[100px]" style="margin: 0 auto">
                    <e-input-number v-model="record.count" :min="1" :max="9999" :disabled="isReadonly"></e-input-number>
                  </div>
                </template>
                <template #operation="{ index }">
                  <span class="operation-model">
                    <a-button type="link" @click="deleteGoodsItem(index)" v-if="!isReadonly">删除</a-button>
                  </span>
                </template>
                <template #footer>
                  <div class="w-full text-center">
                    <a-button
                      :disabled="isReadonly"
                      class="w-[90%] border-[1px] border-[#d9d9d9] border-dashed"
                      @click="addGoodsItem"
                    >
                      <PlusOutlined />
                      <span class="ml-[8px]">添加配件</span>
                    </a-button>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="relative border-l-[2px] border-l-[#ff4d00] pl-[12px] mb-[8px]">
          <div class="absolute left-[-115px] flex items-center">
            <div class="w-[22px] h-[70px] text-[50px] italic mr-[26px] leading-[70px]">2</div>
            <div class="w-[28px] h-[36px]">选择</div>
            <div style="position: absolute; top: 36px; right: -14px">供应商</div>
          </div>

          <div class="bg-[#f5f5f5]">
            <div class="relative h-[76px] flex items-center mx-[24px] border-b-[1px]">
              <div class="mr-[13px]">全车件</div>
              <div>
                <a-tag
                  class="!py-[8px] !px-[12px] my-tag"
                  :closable="!isReadonly"
                  v-for="item of brandCurrentSuppliers"
                  :key="item.supplier.id"
                  @close="deleteBrandSupplier(item)"
                  >{{ item.supplier.shortName }}</a-tag
                >
              </div>
              <a-button
                v-if="brandCurrentSuppliers.length < 3"
                class="dashed !h-38px"
                :disabled="!flag.ishavedVin || isReadonly"
                @click="brandSupplierDrawer.visible = true"
              >
                <PlusOutlined class="text-primary" />
                <span class="ml-[8px] !text-primary">自选商家</span>
              </a-button>
              <div class="absolute right-0" v-if="showRecommandSuppliers.length">
                <span>平台推荐：</span>
                <a-checkbox-group v-model:value="recommendSupplierId">
                  <a-checkbox
                    :disabled="isReadonly"
                    name="type"
                    v-for="item of showRecommandSuppliers"
                    :key="item.supplier.id"
                    :value="item.supplier.id"
                    >{{ item.supplier.shortName }}</a-checkbox
                  >
                </a-checkbox-group>
              </div>
            </div>
            <div class="h-[76px] flex items-center ml-[24px]">
              <div class="mr-[13px]">易损件</div>
              <div>
                <a-tag
                  class="!py-[8px] !px-[12px] my-tag"
                  :closable="!isReadonly"
                  v-for="item of partCurrentSuppliers"
                  :key="item.supplier.id"
                  @close="deletepartSupplier(item)"
                  >{{ item.supplier.shortName }}</a-tag
                >
              </div>
              <a-button
                v-if="partCurrentSuppliers.length < 3"
                class="dashed !h-38px"
                :disabled="isReadonly"
                @click="partSupplierDrawer.visible = true"
              >
                <PlusOutlined class="text-primary" />
                <span class="ml-[8px] text-primary">自选商家</span>
              </a-button>
            </div>
          </div>
        </div>
        <div class="relative border-l-[2px] border-l-[#ffd100] pl-[12px] mb-[8px]">
          <div class="absolute left-[-115px] flex items-center">
            <div class="w-[22px] h-[70px] text-[50px] italic mr-[26px] leading-[70px]">3</div>
            <div class="w-[28px] h-[36px]">备注附件</div>
          </div>
          <div class="bg-[#f5f5f5]">
            <div style="padding: 29px; display: grid; grid-template-columns: 120px auto">
              <e-upload v-model="inquiryData.inquiryImages" :max="1" :disabled="isReadonly"></e-upload>
              <a-textarea
                v-model:value="inquiryData.comment"
                placeholder="请输入其他文字备注，不超过50字"
                :disabled="isReadonly"
                :auto-size="{ minRows: 4, maxRows: 4 }"
                :maxlength="50"
                style="width: 75%; margin-top: 3px"
              />
            </div>
          </div>
        </div>
        <div class="relative border-l-[2px] border-l-[#ffa577] pl-[12px] mb-[24px]">
          <div class="absolute left-[-115px] flex items-center">
            <div class="w-[22px] h-[70px] text-[50px] italic mr-[26px] leading-[70px]">4</div>
            <div class="w-[28px] h-[36px]">其他信息</div>
          </div>
          <div class="bg-[#f5f5f5]">
            <div>
              <span v-if="!isReadonly" class="cursor-pointer ml-13px" @click="isSpread = !isSpread">
                {{ isSpread ? '收起' : '展开查看' }}
                <UpOutlined v-if="isSpread" class="align-baseline" />
                <DownOutlined v-else class="align-baseline" />
              </span>
              <a-form
                :model="inquiryData"
                :class="['spread-block', 'self-form', { 'show-height': isSpread }]"
                style="padding-left: 13px"
              >
                <a-form-item label="收货信息">
                  <template v-if="inquiryData.addressDetail">
                    <span class="mr-[8px]">{{
                      `${formatArea(inquiryData.addressArea)}${inquiryData.addressDetail}`
                    }}</span>
                    <span class="mr-[8px]">
                      {{ inquiryData.addressContactName }}
                    </span>
                    <span class="mr-[8px]">
                      {{ inquiryData.addressCellphone }}
                    </span>
                  </template>
                  <span class="cursor-pointer text-[#0d8d8d]" @click="addressDrawer.visible = true" v-if="!isReadonly"
                    >修改</span
                  >
                </a-form-item>
                <a-form-item label="报价要求">
                  <span class="mr-[8px]">{{
                    inquiryData.required.code === 'Y' ? '商家必填配件编码' : '商家不必填配件编码'
                  }}</span>
                  <span class="cursor-pointer text-[#0d8d8d]" @click="flag.priceModelVisible = true" v-if="!isReadonly"
                    >修改</span
                  >
                </a-form-item>
                <a-form-item label="开票要求">
                  <span class="mr-[8px]" v-if="inquiryData.invoiceType.code === 'N'"> 不需要发票</span>
                  <span class="mr-[8px]" v-else-if="inquiryData.invoiceType.code === 'S'">开增值税发票</span>
                  <span class="mr-[8px]" v-else>开普通发票</span>
                  <span
                    class="cursor-pointer text-[#0d8d8d]"
                    @click="flag.invoiceModelVisible = true"
                    v-if="!isReadonly"
                    >修改</span
                  >
                </a-form-item>
                <a-form-item label="发票抬头" v-if="['S', 'C'].includes(inquiryData.invoiceType.code)">
                  <span class="mr-[8px]">{{ inquiryData.invoiceTitle || '' }}</span>
                  <span class="cursor-pointer text-[#0d8d8d]" @click="invoiceDrawer.visible = true" v-if="!isReadonly"
                    >修改</span
                  >
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
        <div class="ml-[24px] pb-[36px]">
          <div v-if="!isReadonly">
            <template v-if="id !== 'add'">
              <a-button type="primary" @click="beforeSubmitClick('updateAndAudit')" :loading="updateAndAuditLoading"
                >发布询价</a-button
              >
              <a-button
                class="border-[1px] border-[#0d8d8d] text-[#0d8d8d] ml-[26px]"
                @click="beforeSubmitClick('update')"
                :loading="updateLoading"
                >保存</a-button
              >
            </template>
            <template v-else>
              <a-button type="primary" @click="beforeSubmitClick('createAndAudit')" :loading="createAndAuditLoading"
                >发布询价</a-button
              >
              <a-button
                class="border-[1px] border-[#0d8d8d] text-[#0d8d8d] ml-[26px]"
                @click="beforeSubmitClick('create')"
                :loading="createInquiryLoading"
                >保存草稿</a-button
              >
            </template>
          </div>
        </div>
      </div>
    </div>

    <a-drawer title="从EXCEL导入" v-model:visible="excelDrawer.visible" width="480" placement="right">
      <div class="py-[24px] flex justify-end">
        <a-button type="primary" @click="excelDrawer.dowanloadFormClick">下载模板</a-button>
      </div>
      <div>
        <a-upload-dragger name="file" action="/enoquote/client/inquiry/import" @change="excelDrawer.handleChange">
          <div class="flex flex-col items-center">
            <img class="w-[48px] h-[42px] mt-[40px] mb-[30px]" src="@client/assets/icon_frame.png" alt="" />
            <p>将EXCEL文件拖放到这里</p>
            <p class="mt-[5px] mb-[30px]">
              或
              <span class="text-primary">点击选择EXCEL文件</span>
            </p>
          </div>
        </a-upload-dragger>
      </div>
    </a-drawer>

    <!-- 从估价单添加 -->
    <a-drawer
      title="从估价单添加"
      class="ant-drawer__have-footer"
      v-model:visible="serverDrawer.visible"
      width="480"
      placement="right"
    >
      <div>
        <a-form :model="serverDrawer.form.data" class="myform">
          <a-row>
            <a-col :span="12">
              <a-form-item label="VIN码:">
                <a-input v-model:value="serverDrawer.form.data.vin" allowClear placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="车牌:">
                <a-input v-model:value="serverDrawer.form.data.plateNo" allowClear placeholder="请输入" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="21">
              <a-form-item label="时间:">
                <e-datepicker
                  type="range"
                  v-model:start="serverDrawer.form.data.preparedStarDate"
                  v-model:end="serverDrawer.form.data.preparedEndDate"
                ></e-datepicker>
              </a-form-item>
            </a-col>
            <a-col :span="3" class="flex justify-end">
              <a-button type="primary" @click="getserverData">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
        <a-table
          class="tr-margin-table"
          :data-source="serverData"
          :show-header="false"
          :pagination="false"
          row-key="id"
          :loading="getserverDataLoading"
        >
          <a-table-column :customCell="() => ({ style: { border: 'none', padding: '0' } })">
            <template #default="{ record }">
              <div class="flex p-[16px] mb-16px border border-[#e5e7eb]">
                <span class="w-20px mr-[16px]">
                  <a-radio v-model:checked="record.checked" @change="serverDrawer.selectChange(record)"></a-radio>
                </span>
                <div class="flex-1">
                  <div class="flex justify-between items-center">
                    <span class="text-[16px]">{{ record.vehicle.vin }}</span>
                    <span>{{ formatDate(record.preparedDatetime) }}</span>
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="w-200px truncate">{{
                      `${record.vehicle.vehicleSpec.join('  ')} ${
                        record.vehicle.outputVolume
                          ? `${record.vehicle.outputVolume}${record.vehicle.turboCharged.value ? 'T' : 'L'}`
                          : ''
                      }`
                    }}</span>
                    <span class="ml-24px">{{ record.vehicle.plateNo }}</span>
                  </div>
                </div>
              </div>
            </template>
          </a-table-column>
        </a-table>
      </div>

      <div class="drawer-footer">
        <a-button class="mr-[8px]" @click="serverDrawer.visible = false">取消</a-button>
        <a-button type="primary" @click="serverDrawer.confirmClick">确定</a-button>
      </div>
    </a-drawer>

    <a-modal
      v-model:visible="flag.priceModelVisible"
      :keyboard="false"
      :maskClosable="false"
      title="报价要求"
      :footer="null"
      @cancel="updateConfig"
    >
      <div>
        <a-radio-group name="partsGroup" v-model:value="inquiryData.required.code">
          <a-radio value="Y">是</a-radio>
          <a-radio value="N">否</a-radio>
          <span>要求商家必填配件编码</span>
        </a-radio-group>
      </div>
    </a-modal>

    <a-modal
      v-model:visible="flag.invoiceModelVisible"
      title="开票要求"
      :keyboard="false"
      :maskClosable="false"
      :footer="null"
      @cancel="updateConfig"
    >
      <div>
        <a-radio-group name="invoiceGroup" v-model:value="inquiryData.invoiceType.code">
          <a-radio :value="item.code" v-for="item of invoiceTypeData" :key="item.code">{{ item.message }}</a-radio>
        </a-radio-group>
      </div>
    </a-modal>

    <a-modal
      v-model:visible="messageModal.visaible"
      :title="messageModal.type === 'save' ? '保存成功' : '发布成功'"
      :closable="false"
      :footer="null"
      :maskClosable="false"
    >
      <div class="flex justify-center items-center">
        <div>
          <div class="w-[64px] h-[64px] mx-auto">
            <img src="@client/assets/success.png" class="w-full" />
          </div>
          <div class="font-bold text-[20px] mb-[46px] mt-[32px] text-center">
            {{ messageModal.type === 'save' ? '询价单已保存' : '询价单已生成' }}
          </div>
          <div>
            <a-button class="mr-[8px] text-[#fff]" @click="messageModal.goClient" type="primary">返回首页</a-button>
            <a-button @click="messageModal.goInquery">查看询价单</a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <drawer-inovice
      v-model:visible="invoiceDrawer.visible"
      is-confirm
      @confirm="invoiceDrawer.confirm"
    ></drawer-inovice>
    <drawer-address
      v-model:visible="addressDrawer.visible"
      is-confirm
      @confirm="addressDrawer.confirm"
    ></drawer-address>

    <!-- 选择商家 -->

    <drawer-supplier
      v-model:visible="brandSupplierDrawer.visible"
      :suppliers="brandCurrentSuppliers"
      :vehicleBrand="inquiryData.vehicleBrand"
      businessType="W"
      :excludedSupplierIds="excludedSupplierIds"
      @confirm="brandSupplierDrawer.confirm"
    ></drawer-supplier>

    <drawer-supplier
      v-model:visible="partSupplierDrawer.visible"
      :suppliers="partCurrentSuppliers"
      businessType="CU"
      activeKey="all"
      :excludedSupplierIds="excludedSupplierIds"
      @confirm="partSupplierDrawer.confirm"
    ></drawer-supplier>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, defineComponent, PropType, watch, h, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAjax, formatArea, formatDate } from '@vue-mfe/utils'
import {
  message,
  Checkbox as ACheckbox,
  CheckboxGroup as ACheckboxGroup,
  Tag as ATag,
  Drawer as ADrawer,
  Modal as AModal,
  Radio as ARadio,
  RadioGroup as ARadioGroup,
  InputSearch as AInputSearch,
  Row as ARow,
  Col as ACol,
  FormItem as AFormItem,
  Select as ASelect,
  UploadDragger as AUploadDragger
} from 'ant-design-vue'
import {
  PlusOutlined,
  // MinusOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons-vue'

import DrawerInovice from '@client/components/drawer-invoice.vue'
import DrawerAddress from '@client/components/drawer-address.vue'
import DrawerSupplier from '@client/components/drawer-supplier.vue'
import { cloneDeep, debounce } from 'lodash-es'

interface InquiryData {
  vin: string
  inquiryQualityTypes: any[]
  qualityTypeCodes: string[]
  inquiryImages: any[]
  comment: string
  addressContactName: string
  addressCellphone: string
  addressDetail: string
  addressArea: any
  invoiceType: any
  required: any
  invoiceTitle: string
  invoiceTaxNo: string
  invoiceBankName: string
  invoiceBankAccount: string
  invoiceCompanyAddress: string
  invoiceCompanyPhone: string
  suppliers: any[]
  inquiryGoods: any[]
  tableGoods: any[]
  vehicleBrand: string
  vehicleDisplacement: string
  vehicleEngine: string
  vehicleGroup: string
  vehiclePrefix: string
  vehicleSubBrand: string
  vehicleSystem: string
  vehicleTransmission: string
  vehicleYear: string
  vehiclePlateNo: string
}

interface QualityState {
  code: string
  message: string
}

interface GoodsState {
  id: number | string
  name: string
  serialNo?: string
}

interface PlanGoods {
  id: string
  count: number
  name: string
}

interface ServerData {
  id: string | number
  planGoods: PlanGoods[]
  preparedDatetime: string
  serialNo: string
  vehicle: any
  checked?: boolean
}

const ASelectGoods = defineComponent(
  {
    props: {
      modelValue: Object as PropType<GoodsState>
    },

    setup (props, { emit, attrs }) {
      const search = ref<string>('')
      const options = ref<(GoodsState & { label?: string; value?: number | string })[]>([])
      const { run: getOptions } = useAjax<GoodsState & { label?: string; value?: number | string }>(
        {
          action: 'GET /client/common/goods',
          params: () => ({ quickSearch: search.value }),
          convert: {
            client (data) {
              if (search.value && !options.value.find((item) => item.id === 'x' && item.name === search.value)) {
                data.unshift({ name: search.value, id: 'x' })
              }

              return data.map(
                (item) => {
                  item.label = item.name
                  item.value = item.id
                  return item
                }
              )
            }
          },
          success (data) {
            options.value = data
          },
          lazy: true
        }
      )

      const onSearch = debounce(
        (val) => {
          search.value = val.slice(0, 12)
          if (!options.value.find((item) => item.id === 'x' && item.name === val)) getOptions()
        },
        1000
      )

      watch(
        () => props.modelValue,
        async (modelValue) => {
          await getOptions()
          if (modelValue && modelValue.id && modelValue.id === 'x') {
            options.value.unshift(
              { label: modelValue.name, value: modelValue.id, id: modelValue.id, name: modelValue.name }
            )
          }
        },
        {
          immediate: true
        }
      )

      return () =>
        h(
          ASelect,
          {
            attrs,
            value: props.modelValue && props.modelValue.id,
            filterOption: false,
            style: { width: '100%' },
            showSearch: true,
            onSearch: onSearch,
            options: options.value,
            maxTagTextLength: 12,
            getPopupContainer: (triggerNode) => triggerNode.parentNode || document.body,
            onBlur: () => {
              if (props.modelValue && !props.modelValue.id && options.value.length) {
                emit('update:modelValue', { id: options.value[0].value, name: options.value[0].label })
              }
            },
            onSelect (val) {
              const res = options.value.find((item) => item.value === val)
              if (val === 'x') {
                emit(
                  'update:modelValue',
                  {
                    id: val,
                    // @ts-ignore
                    name: options.value.find((item) => item.value === val).label
                  }
                )
                // @ts-ignore
              } else emit('update:modelValue', { id: res.value, name: res.label })
              emit('change', val)
            }
          },
          {
            option: ({ label, value }: { label: string; value: string | number }) => {
              const current = options.value.find((item) => item.value === value)
              return h(
                'div',
                { class: 'flex justify-between' },
                [
                  h('span', label),
                  h('span', { class: 'text-gray-500' }, value !== 'x' && current ? current.serialNo : '')
                ]
              )
            }
          }
        )
    }
  }
)

const router = useRouter()
const route = useRoute()
const id = route.params.id
const isReadonly = route.query.isReadonly === 'Y' ? true : false
const reInquiry = route.query.reInquiry === 'Y' ? true : false
const vinInquiry = route.query.vinInquiry as string
const isSpread = ref(false)

// elcel导入
const excelDrawer = reactive(
  {
    visible: false,
    dowanloadFormClick: () => {
      window.open(
        'https://enocherp-oss01.oss-cn-hangzhou.aliyuncs.com/enoquote/%E8%AF%A2%E4%BB%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      )
    },
    handleChange: (info: any) => {
      const status = info.file.status
      if (status === 'done') {
        message.success('导入成功')
        const data = info.file.response.data
        inquiryData.inquiryGoods = data.map(
          (item: any) => {
            if (!item.enocloudGoods)
              item.enocloudGoods = {
                id: 'x',
                name: item.name
              }
            return item
          }
        )
        excelDrawer.visible = false
      } else if (status === 'error') {
        message.success('导入失败')
      }
    }
  }
)
// 估价单导入
const serverDrawer = reactive(
  {
    visible: false,
    form: {
      data: {
        vin: '',
        plateNo: '',
        preparedStarDate: '',
        preparedEndDate: ''
      },
      init: () => {
        serverDrawer.form.data.vin = ''
        serverDrawer.form.data.plateNo = ''
        serverDrawer.form.data.preparedStarDate = ''
        serverDrawer.form.data.preparedEndDate = ''
      }
    },
    checkedRow: {
      planGoods: [],
      vehicle: {
        vin: ''
      }
    },
    selectChange: (row: any) => {
      if (row.checked) {
        serverDrawer.checkedRow = row
        serverData.value = serverData.value.map(
          (item) => {
            if (item.id !== row.id) item.checked = false
            return item
          }
        )
      } else {
        serverDrawer.checkedRow = {
          planGoods: [],
          vehicle: {
            vin: ''
          }
        }
      }
    },
    open: () => {
      serverDrawer.form.init()
      serverDrawer.form.data.vin = inquiryData.vin || ''
      getserverData()
      serverDrawer.checkedRow = { planGoods: [], vehicle: { vin: '' } }
      serverDrawer.visible = true
    },
    cancelClick: () => {},
    confirmClick: () => {
      inquiryData.vin = serverDrawer.checkedRow.vehicle.vin.replace(/\W/g, '').toLocaleUpperCase()
      // 修改数据格式，并且过滤不合格数据，没有配件名称就为无效数据
      let data = serverDrawer.checkedRow.planGoods
        .map(
          (item: any) => {
            if (!item.enocloudGoods)
              item.enocloudGoods = {
                id: 'x',
                name: item.name
              }
            return item
          }
        )
        .filter((item: any) => item.enocloudGoods.name)
      // 过滤不合格数据，没有配件名称就为无效数据
      inquiryData.inquiryGoods = inquiryData.inquiryGoods.filter((item: any) => item.enocloudGoods.name).concat(data)
      nextTick(
        () => {
          serverDrawer.visible = false
          vinSearchClick()
        }
      )
    }
  }
)

const {
  data: serverData,
  run: getserverData,
  loading: getserverDataLoading
} = useAjax<ServerData>(
  {
    action: 'GET /client/common/service/quotation',
    params: () => ({ ...serverDrawer.form.data, pageSize: 10 }),
    convert: {
      client (data) {
        return data.map(
          (item: ServerData) => {
            item.checked = false
            return item
          }
        )
      }
    },
    lazy: true
  }
)

let inquiryData = reactive<InquiryData>(
  {
    inquiryQualityTypes: [],
    qualityTypeCodes: ['O', 'B'],
    vin: '',
    inquiryImages: [],
    comment: '',
    addressContactName: '',
    addressCellphone: '',
    addressDetail: '',
    addressArea: {
      code: '',
      parent: {}
    },
    invoiceType: {
      code: ''
    },
    required: {
      code: ''
    },
    invoiceTitle: '',
    invoiceTaxNo: '',
    invoiceBankName: '',
    invoiceBankAccount: '',
    invoiceCompanyAddress: '',
    invoiceCompanyPhone: '',
    suppliers: [],
    inquiryGoods: [],
    tableGoods: [],
    vehicleBrand: '',
    vehicleDisplacement: '',
    vehicleEngine: '',
    vehicleGroup: '',
    vehiclePrefix: '',
    vehicleSubBrand: '',
    vehicleSystem: '',
    vehicleTransmission: '',
    vehicleYear: '',
    vehiclePlateNo: ''
  }
)

const flag = reactive(
  {
    ishavedVin: false,
    priceModelVisible: false,
    invoiceModelVisible: false,
    addAddressBrand: false,
    invoiceTitle: '新建发票',
    addressTitle: '新建收货地址',
    addInvoiceBrand: false
  }
)

const { run: getVehicleBrand } = useAjax(
  {
    action: 'GET /client/mingjue/vehicle/:vin',
    params: () => [inquiryData.vin],
    convert: {
      client (data) {
        inquiryData.vehicleBrand = data[0].brand
        inquiryData.vehicleDisplacement = data[0].displacement
        inquiryData.vehicleEngine = data[0].engine
        inquiryData.vehiclePrefix = data[0].prefix
        inquiryData.vehicleSubBrand = data[0].subBrand
        inquiryData.vehicleTransmission = data[0].transmission
        inquiryData.vehicleGroup = data[0].vehicleGroup
        inquiryData.vehicleSystem = data[0].vehicleSys
        inquiryData.vehicleYear = data[0].year
        inquiryData.vehiclePlateNo = data[0].plateNo
        return data
      }
    },
    success () {
      flag.ishavedVin = true
      getRecommendSupplier().then(
        (res) => {
          if (res.status === 200 && id === 'add' && !reInquiry) {
            getCollect()
          }
        }
      )
    },
    lazy: true
  }
)

// 获取品质类型
const { data: qualityData } = useAjax<QualityState>(
  {
    action: 'GET /common/lookup/:lookupType',
    params: 'IQYQLYTYPE',
    convert: {
      client (data) {
        data.forEach(
          (item: any) => {
            item.message = item.message.slice(0, 2)
            return item
          }
        )
        return data
      }
    }
  }
)

const vinSearchClick = () => {
  if (inquiryData.vin.length === 17) {
    getVehicleBrand()
  } else {
    return message.warning('请输入正确的vin码!')
  }
}

const vinSearchChange = () => {
  if (!inquiryData.vin) {
    flag.ishavedVin = false
    inquiryData.vehicleBrand = ''
    inquiryData.vehicleDisplacement = ''
    inquiryData.vehicleEngine = ''
    inquiryData.vehiclePrefix = ''
    inquiryData.vehicleSubBrand = ''
    inquiryData.vehicleTransmission = ''
    inquiryData.vehicleGroup = ''
    inquiryData.vehicleSystem = ''
    inquiryData.vehicleYear = ''
    inquiryData.vehiclePlateNo = ''
  } else {
    inquiryData.vin = inquiryData.vin.replace(/\W/g, '').toLocaleUpperCase()
    if (inquiryData.vin.length >= 17) {
      inquiryData.vin = inquiryData.vin.slice(0, 17)
      getVehicleBrand()
    }
  }
}

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: '5%',
    slots: { customRender: 'index' }
  },
  {
    dataIndex: 'name',
    slots: { title: 'customName', customRender: 'name' }
  },
  {
    dataIndex: 'count',
    width: '35%',
    align: 'center',
    slots: { title: 'customCount', customRender: 'count' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '10%',
    align: 'center',
    slots: { customRender: 'operation' }
  }
]

const initGoodsItem = () => {
  return {
    id: '',
    enocloudGoods: {
      id: undefined,
      name: ''
    },
    name: '',
    count: 1
  }
}

const deleteGoodsItem = (index: any) => {
  inquiryData.inquiryGoods.splice(index, 1)
}

const addGoodsItem = () => {
  inquiryData.inquiryGoods.push(initGoodsItem())
}

const onTableEnocloudGoodsChange = (record: any) => {
  record.name = record.enocloudGoods.name
  // if (record.enocloudGoods.id === 'x') record.enocloudGoods = {}
}

// 获取收货信息
const { run: getAddress } = useAjax(
  {
    action: `GET /client/address`,
    success (data) {
      if (data[0]) {
        const address = data[0]
        inquiryData.addressContactName = address.contactName
        inquiryData.addressCellphone = address.cellphone
        inquiryData.addressDetail = address.addressDetail
        inquiryData.addressArea = address.area
      }
    },
    lazy: true
  }
)

const addressDrawer = reactive(
  {
    visible: false,
    confirm: (address: any) => {
      inquiryData.addressContactName = address.contactName
      inquiryData.addressCellphone = address.cellphone
      inquiryData.addressDetail = address.addressDetail
      inquiryData.addressArea = address.area
      addressDrawer.visible = false
    }
  }
)

// 获取发票抬头
const { run: getInvoice } = useAjax(
  {
    action: `GET /client/invoice`,
    success (data) {
      if (data[0]) {
        inquiryData.invoiceTitle = data[0].invoiceTitle
        inquiryData.invoiceTaxNo = data[0].taxNo
        inquiryData.invoiceBankName = data[0].bankName
        inquiryData.invoiceBankAccount = data[0].bankAccount
        inquiryData.invoiceCompanyAddress = data[0].companyAddress
        inquiryData.invoiceCompanyPhone = data[0].companyPhone
      }
    },
    lazy: true
  }
)

const invoiceDrawer = reactive(
  {
    visible: false,
    confirm: (res: any) => {
      inquiryData.invoiceTitle = res.invoiceTitle
      inquiryData.invoiceTaxNo = res.taxNo
      inquiryData.invoiceBankName = res.bankName
      inquiryData.invoiceBankAccount = res.bankAccount
      inquiryData.invoiceCompanyAddress = res.companyAddress
      inquiryData.invoiceCompanyPhone = res.companyPhone
      invoiceDrawer.visible = false
    }
  }
)

// 获取开票要求
const { data: invoiceTypeData } = useAjax(
  {
    action: 'GET /common/lookup/:lookupType',
    params: 'IVCTP',
    convert: {
      client (data: any) {
        return data.sort(
          (cur: any, next: any) => {
            const orderIndex = ['S', 'C', 'N']
            return orderIndex.indexOf(cur.code) - orderIndex.indexOf(next.code)
          }
        )
      }
    }
  }
)

// 获取报价配置
const { run: getConfig } = useAjax(
  {
    action: 'GET /client/common/config',
    convert: {
      client (data) {
        inquiryData.required.code = data[0].required.code
        inquiryData.invoiceType.code = data[0].invoiceType.code
        return data
      }
    },
    lazy: true
  }
)
// 修改报价配置
const { run: updateConfig } = useAjax(
  {
    action: 'PUT /client/common/config',
    params: () => ({ required: inquiryData.required, invoiceType: inquiryData.invoiceType }),
    lazy: true
  }
)

// 已保存的平台推荐商家
let recommendSuppliers = ref<any[]>([])

// 平台推荐商家的ID
let recommendSupplierId = ref<number[]>([])

// 剔除的平台推荐的ID
// let excludedSupplierIds = ref<number[]>([])

// 全车件商家
let brandCurrentSuppliers = ref<any[]>([])
// 易损件商家
let partCurrentSuppliers = ref<any[]>([])

const brandSupplierDrawer = reactive(
  {
    visible: false,
    confirm: (value: any[]) => {
      brandCurrentSuppliers.value = value
    }
  }
)
const partSupplierDrawer = reactive(
  {
    visible: false,
    confirm: (value: any[]) => {
      partCurrentSuppliers.value = value
    }
  }
)

// 删除全车件商家
const deletepartSupplier = (item: any) => {
  const index = partCurrentSuppliers.value.findIndex((inner: any) => inner.supplier.id === item.supplier.id)
  partCurrentSuppliers.value.splice(index, 1)
}

// 删除易损件商家
const deleteBrandSupplier = (item: any) => {
  const index = brandCurrentSuppliers.value.findIndex((inner: any) => inner.supplier.id === item.supplier.id)
  brandCurrentSuppliers.value.splice(index, 1)
}

// 从关注商家里获取推荐
const { run: getCollect } = useAjax(
  {
    action: 'GET /client/supplier/collect',
    params: () => [
      {
        excludedSupplierIds: excludedSupplierIds.value,
        vehicleBrand: inquiryData.vehicleBrand,
        businessType: 'W'
      }
    ],
    success (res) {
      brandCurrentSuppliers.value = res
        .slice(0, 3)
        .map((item: any) => ({ supplier: { id: item.supplier.id, shortName: item.supplier.shortName } }))
    },
    lazy: true
  }
)

// 获取平台推荐
// 查询全部商家需要传入平台推荐的商家的ID用来剔除
const { data: recommendData, run: getRecommendSupplier } = useAjax(
  {
    action: 'GET /client/common/supplier',
    params: () => [
      {
        pageSize: 3,
        vehicleBrand: inquiryData.vehicleBrand,
        businessType: 'W'
      }
    ],
    convert: {
      client (data) {
        return data.map((item: any) => ({ supplier: { id: item.id, shortName: item.shortName } }))
      }
    },
    lazy: true
  }
)

const mergeSuppler = () => {
  const brandSuppliers = brandCurrentSuppliers.value.map(
    (item: any) => {
      if (!item.type)
        item.type = {
          code: 'VP'
        }
      return item
    }
  )
  const partSuppliers = partCurrentSuppliers.value.map(
    (item: any) => {
      if (!item.type) item.type = { code: 'UP' }
      return item
    }
  )
  const recommendSup = [] as any[]
  showRecommandSuppliers.value.forEach(
    (item: any) => {
      if (recommendSupplierId.value.includes(item.supplier.id)) {
        if (!item.type) item.type = { code: 'R' }
        recommendSup.push(item)
      }
    }
  )
  inquiryData.suppliers = recommendSup.concat(brandSuppliers, partSuppliers)
}

// 验证数据并提交
const beforeSubmitClick = (value: any) => {
  mergeSuppler()
  inquiryData.inquiryQualityTypes = qualityData.value.filter(
    (item: any) => inquiryData.qualityTypeCodes.includes(item.code)
  )
  let isNoName = false

  inquiryData.tableGoods = cloneDeep(inquiryData.inquiryGoods)
  inquiryData.tableGoods = inquiryData.tableGoods.filter((item: any) => item.enocloudGoods.id)
  inquiryData.tableGoods.forEach(
    (item: any) => {
      if (item.enocloudGoods.id === 'x') {
        item.enocloudGoods = {}
      }
    }
  )
  if (!inquiryData.inquiryQualityTypes.length) {
    return message.warning('请勾选品质类型!')
  }
  if (isNoName || !inquiryData.inquiryGoods.length) {
    return message.warning('请先选择配件')
  }
  if (!inquiryData.suppliers.length) {
    return message.warning('请至少选择一个供应商!')
  }
  if (!inquiryData.addressArea.code) {
    return message.warning('请选择收货地址!')
  }
  if (!inquiryData.invoiceType.code || !inquiryData.required.code) {
    return message.warning('请选择报价要求或开票要求')
  }
  if (inquiryData.invoiceType.code !== 'N' && inquiryData.invoiceTitle === '') {
    return message.warning('请选择发票抬头!')
  }
  if (value === 'create') {
    createInquiry().then(
      (res: any) => {
        if (res && res.request.status === 200) {
          messageModal.type = 'save'
          messageModal.visaible = true
        }
      }
    )
  }
  if (value === 'createAndAudit') {
    createAndAudit().then(
      (res: any) => {
        if (res && res.request.status === 200) {
          messageModal.type = 'createAndAudit'
          messageModal.visaible = true
        }
      }
    )
  }
  if (value === 'update') {
    updateInquiry().then(
      (res: any) => {
        if (res && res.request.status === 200) {
          messageModal.type = 'save'
          messageModal.visaible = true
        }
      }
    )
  }
  if (value === 'updateAndAudit') {
    updateAndAudit()
  }
}

// 创建并发布询价单
const { run: createAndAudit, loading: createAndAuditLoading } = useAjax(
  {
    action: 'POST /client/inquiry/createAndAudit',
    params: () => ({ ...inquiryData, inquiryGoods: inquiryData.tableGoods }),
    lazy: true
  }
)

// 创建询价单
const { run: createInquiry, loading: createInquiryLoading } = useAjax(
  {
    action: 'POST /client/inquiry',
    params: () => ({ ...inquiryData, inquiryGoods: inquiryData.tableGoods }),
    lazy: true
  }
)
// 更新并发布询价单
const { run: updateAndAudit, loading: updateAndAuditLoading } = useAjax(
  {
    action: 'PUT /client/inquiry/updateAndAudit',
    params: () => ({ ...inquiryData, inquiryGoods: inquiryData.tableGoods, id }),
    success () {
      messageModal.type = 'updateAndAudit'
      messageModal.visaible = true
    },
    lazy: true
  }
)
// 发布询价单
const { run: updateInquiry, loading: updateLoading } = useAjax(
  {
    action: 'PUT /client/inquiry',
    params: () => ({ ...inquiryData, inquiryGoods: inquiryData.tableGoods, id }),
    success () {
      message.success('保存成功！')
    },
    lazy: true
  }
)
// 获取询价单详情
const { run: getInqueryDetail } = useAjax(
  {
    action: 'GET /client/inquiry/:inquiryId',
    params: id,
    success (res) {
      const data = res[0]
      data.qualityTypeCodes = data.inquiryQualityTypes.map((item: any) => item.code)
      if (data.vin) {
        flag.ishavedVin = true
      }
      const inquiryGoods = [] as any[]
      data.inquiryGoods.forEach(
        (item: any) => {
          if (!item.enocloudGoods) item.enocloudGoods = { id: 'x', name: item.name }
          inquiryGoods.push(item)
        }
      )
      data.suppliers.forEach(
        (item: any) => {
          if (item.type.code === 'UP') {
            partCurrentSuppliers.value.push(item)
          } else if (item.type.code === 'VP') {
            brandCurrentSuppliers.value.push(item)
          } else {
            recommendSuppliers.value.push(item)
            // recommendSupplierId.value.push(item.supplier.id)
          }
        }
      )
      inquiryData = Object.assign(inquiryData, data, { inquiryGoods })
    },
    lazy: true
  }
)

// 展示的平台推荐商家
const showRecommandSuppliers = computed(
  () => {
    let res = [] as any[]
    // 已保存的推荐商家
    res = [...recommendSuppliers.value]
    const haveId = recommendSuppliers.value.map((item: any) => item.supplier.id)
    for (let i = 0; i < recommendData.value.length; i++) {
      // 推荐的平台商家,不能和已有的重复
      if (!haveId.includes(recommendData.value[i].supplier.id)) res.push(recommendData.value[i])
    }
    // 只有三条数据
    if (res.length > 3) res = res.slice(0, 3)
    recommendSupplierId.value = res.map((item: any) => item.supplier.id)
    return res
  }
)

// 剔除的平台推荐的ID
const excludedSupplierIds = computed(
  () => {
    return showRecommandSuppliers.value.map((item: any) => item.supplier.id)
  }
)

const messageModal = reactive(
  {
    visaible: false,
    type: '',
    goClient: () => {
      router.push({ path: '/client' })
    },
    goInquery: () => {
      if (messageModal.type === 'save') {
        router.push({ path: '/client/purchase/inquiry', query: { inquiryStatus: 'P' } })
      } else {
        router.push('/client/purchase/inquiry')
      }
    }
  }
)

const InquiryAgain = () => {
  const localData = localStorage.getItem('INQUIRY_DATA')
  if (!localData) return false
  const data = JSON.parse(localData) as any
  inquiryData.inquiryGoods = data.inquiryGoods.map(
    (item: any) => {
      return {
        name: item.name,
        count: item.count,
        enocloudGoods: {
          id: item.enocloudGoods ? item.enocloudGoods.id : 'x',
          name: item.enocloudGoods ? item.enocloudGoods.name : item.name
        }
      }
    }
  )
  inquiryData.inquiryQualityTypes = data.inquiryQualityTypes
  inquiryData.qualityTypeCodes = data.inquiryQualityTypes.map((item: any) => item.code)
  inquiryData.inquiryImages = data.inquiryImages
  inquiryData.comment = data.comment
  inquiryData.vin = data.vin
  inquiryData.vehicleBrand = data.vehicleBrand
  inquiryData.vehicleDisplacement = data.vehicleDisplacement
  inquiryData.vehicleEngine = data.vehicleEngine
  inquiryData.vehiclePrefix = data.vehiclePrefix
  inquiryData.vehicleSubBrand = data.vehicleSubBrand
  inquiryData.vehicleTransmission = data.vehicleTransmission
  inquiryData.vehicleGroup = data.vehicleGroup
  inquiryData.vehicleSystem = data.vehicleSystem
  inquiryData.vehicleYear = data.vehicleYear
  inquiryData.vehiclePlateNo = data.vehiclePlateNo
  inquiryData.invoiceType = data.invoiceType
  inquiryData.invoiceTitle = data.invoiceTitle
  inquiryData.invoiceTaxNo = data.invoiceTaxNo
  inquiryData.invoiceBankName = data.invoiceBankName
  inquiryData.invoiceBankAccount = data.invoiceBankAccount
  inquiryData.invoiceCompanyAddress = data.invoiceCompanyAddress
  inquiryData.invoiceCompanyPhone = data.invoiceCompanyPhone
  inquiryData.required = data.required
  inquiryData.addressContactName = data.addressContactName
  inquiryData.addressCellphone = data.addressCellphone
  inquiryData.addressDetail = data.addressDetail
  inquiryData.addressArea = data.addressArea
  if (data.vin) {
    flag.ishavedVin = true
  }

  data.suppliers.forEach(
    (item: any) => {
      if (item.type.code === 'UP') {
        partCurrentSuppliers.value.push(item)
      } else if (item.type.code === 'VP') {
        brandCurrentSuppliers.value.push(item)
      } else {
        recommendSuppliers.value.push(item)
        // recommendSupplierId.value.push(item.supplier.id)
      }
    }
  )
  localStorage.removeItem('INQUIRY_DATA')
}
const initPage = () => {
  //  新建询价
  if (id === 'add' && !reInquiry) {
    for (let i = 0; i < 3; i++) {
      inquiryData.inquiryGoods.push(initGoodsItem())
    }
    getConfig()
    getInvoice()
    getAddress()
  }
  // 从新询价
  if (id === 'add' && reInquiry) InquiryAgain()

  //估价单询价
  if (id === 'add' && vinInquiry) {
    serverDrawer.form.data.vin = vinInquiry
    inquiryData.vin = vinInquiry
    vinSearchClick()
    getserverData().then(
      () => {
        let planGoods = serverData.value[0].planGoods
          .map(
            (item: any) => {
              if (!item.enocloudGoods)
                item.enocloudGoods = {
                  id: 'x',
                  name: item.name
                }
              return item
            }
          )
          .filter((item: any) => item.enocloudGoods.name)
        inquiryData.inquiryGoods = planGoods
      }
    )
  }
  // 详情
  if (id && id !== 'add') {
    getInqueryDetail().then(
      () => {
        if (inquiryData.vin) getRecommendSupplier()
      }
    )
  }
}
initPage()
</script>

<style scoped>
.mySelectionTable :deep(.ant-table-selection) {
  display: none;
}

.spread-block {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

.spread-block.show-height {
  max-height: 666px;
}

.self-form :deep(.ant-form-item) {
  margin-bottom: 0;
}
.tr-margin-table :deep(.ant-table-tbody table) {
  border: none;
  /* border-collapse: separate;
  border-spacing: 10px; */
}
.tr-margin-table :deep(.ant-table-tbody td) {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.my-tag.ant-tag :deep(.anticon) {
  margin-left: 10px;
}
.my-tag.ant-tag:hover {
  border-color: rgba(13, 141, 141, 1);
}
.my-tag.ant-tag:hover :deep(.anticon) {
  color: rgba(13, 141, 141, 1);
}
</style>
